exports.components = {
  "component---src-components-blog-blog-page-template-tsx": () => import("./../../../src/components/blog/blogPageTemplate.tsx" /* webpackChunkName: "component---src-components-blog-blog-page-template-tsx" */),
  "component---src-components-session-session-page-template-tsx": () => import("./../../../src/components/session/sessionPageTemplate.tsx" /* webpackChunkName: "component---src-components-session-session-page-template-tsx" */),
  "component---src-components-speakers-speaker-page-template-tsx": () => import("./../../../src/components/speakers/speakerPageTemplate.tsx" /* webpackChunkName: "component---src-components-speakers-speaker-page-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-code-of-conduct-index-en-mdx": () => import("./../../../src/pages/code-of-conduct/index.en.mdx" /* webpackChunkName: "component---src-pages-code-of-conduct-index-en-mdx" */),
  "component---src-pages-code-of-conduct-index-fr-mdx": () => import("./../../../src/pages/code-of-conduct/index.fr.mdx" /* webpackChunkName: "component---src-pages-code-of-conduct-index-fr-mdx" */),
  "component---src-pages-data-schedule-tsx": () => import("./../../../src/pages/data-schedule.tsx" /* webpackChunkName: "component---src-pages-data-schedule-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-mentions-mdx": () => import("./../../../src/pages/legal-mentions.mdx" /* webpackChunkName: "component---src-pages-legal-mentions-mdx" */),
  "component---src-pages-our-values-index-en-mdx": () => import("./../../../src/pages/our-values/index.en.mdx" /* webpackChunkName: "component---src-pages-our-values-index-en-mdx" */),
  "component---src-pages-our-values-index-fr-mdx": () => import("./../../../src/pages/our-values/index.fr.mdx" /* webpackChunkName: "component---src-pages-our-values-index-fr-mdx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-schedule-1-tsx": () => import("./../../../src/pages/schedule/1.tsx" /* webpackChunkName: "component---src-pages-schedule-1-tsx" */),
  "component---src-pages-schedule-2-tsx": () => import("./../../../src/pages/schedule/2.tsx" /* webpackChunkName: "component---src-pages-schedule-2-tsx" */),
  "component---src-pages-schedule-index-tsx": () => import("./../../../src/pages/schedule/index.tsx" /* webpackChunkName: "component---src-pages-schedule-index-tsx" */),
  "component---src-pages-speakers-tsx": () => import("./../../../src/pages/speakers.tsx" /* webpackChunkName: "component---src-pages-speakers-tsx" */),
  "component---src-pages-team-index-en-mdx": () => import("./../../../src/pages/team/index.en.mdx" /* webpackChunkName: "component---src-pages-team-index-en-mdx" */),
  "component---src-pages-team-index-fr-mdx": () => import("./../../../src/pages/team/index.fr.mdx" /* webpackChunkName: "component---src-pages-team-index-fr-mdx" */)
}

